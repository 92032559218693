import { stringify } from 'query-string';
import { transformJSON } from '../utils';
import { store } from '../store/store';

export default (
  url,
  method,
  params,
  data,
  authType,
  contentType,
  bodyExtractor = (response) => response.json(),
) => {
  let apiServer = "https://dev.ronesoft.com";
  // if (window.location.hostname.indexOf('localhost') >= 0) {
  //   apiServer = 'https://dev.ronesoft.com';
  // } else {
  //   apiServer = `https://${window.location.hostname}`;
  // }


  const bearerToken = store.getState().account.bearerToken;

  if (authType === 'OAuth' && !bearerToken) {
    throw new Error('Attempted OAuth API Request with no access token. Make sure the OAuth token is set on the config object.');
  }

  let apiEndPoint = `${apiServer}${url}`;
  // If query parameters were provided, append them to the apiEndPoint
  if (params) {
    Object.keys(params).forEach((key) => {
      const paramValue = params[key];
      if (
        paramValue === undefined
        || paramValue === null
      ) {
        // eslint-disable-next-line no-param-reassign
        delete params[key];
      }
    });

    if (Object.keys(params).length) {
      apiEndPoint = `${apiEndPoint}?${stringify(params)}`;
    }
  }
  
  const headers = new Headers();
  let body = undefined;
  if (contentType === 'multipart/form-data') {
    body = data;
  } else {
    body = data ? JSON.stringify(data) : undefined;
    headers.append('Content-Type', 'application/json')
  }

  headers.append('Pragma', 'no-cache');

  if (authType === 'OAuth') {
    headers.append('Authorization', `Bearer ${bearerToken}`);
  }

  const options = {
    method,
    headers,
    body,
  };

  let controller;
  const promise = fetch(apiEndPoint, options)
    .then(async (response) => {
      if (!response.ok) {
        throw response;
      }

      if (response.status === 204) {
        return response;
      }

      // //  let's check to see if the api returned a success response and had no response data
      // const test = await response.clone().text();
      // console.log('TEST : ', test);
      // if (response.status === 200 && test.length === 0) {
      //   return response;
      // }


      return response.json()
        .then((json) => {
          return (typeof json === 'string' ? JSON.parse(json) : json)
        }).then((json) => transformJSON(json));
    })
    .catch(async (error) => {
      throw error;
    });

  if (controller) {
    promise.controller = controller;
  }

  return promise;
};
