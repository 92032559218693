const capitalize = (value) => value.charAt(0).toUpperCase() + value.slice(1);

const underscoreToCamelCase = (value) => value.toLowerCase()
  .split('_')
  .map((word, index) => (index > 0 ? capitalize(word) : word))
  .join('');

const camelToUnderscore = (value) => value.split(/(?=[A-Z])/).join('_').toUpperCase();

const formatDollar = (num) => {
  var p = num.toFixed(2).split(".");
  return "$" + p[0].split("").reverse().reduce(function(acc, num, i, orig) {
      return num + (num !== "-" && i && !(i % 3) ? "," : "") + acc;
  }, "") + "." + p[1];
}

const formatNumber = (num) => {
  return num.toLocaleString(undefined, { minimumFractionDigits: 0 });
}

const transformJSON = (o) => {
  if (o instanceof Array) {
    return o.map((value) => {
      if (typeof value === 'object') {
        return transformJSON(value);
      }

      return value;
    });
  }

  const newO = {};

  Object.entries(o).forEach(([key, value]) => {
    // Remove any dots from the key
    let newKey = key.replace(/\./g, '');

    if (key.includes('_')) {
      // Convert snake case to camel case
      newKey = underscoreToCamelCase(key);
    } else {
      // Convert pascal case to camel case
      newKey = newKey.charAt(0).toLowerCase() + newKey.slice(1);
    }

    let newValue = value;

    if (value instanceof Array || (value !== null && value.constructor === Object)) {
      newValue = transformJSON(value);
    } else if (typeof value === 'string') {
      // Convert to proper booleans (received as strings from api)
      const lowercaseValue = value.toLowerCase();

      if (lowercaseValue === 'true') {
        newValue = true;
      } else if (lowercaseValue === 'false') {
        newValue = false;
      }
    }

    newO[newKey] = newValue;
  });

  return newO;
};

const getCRUDActions = (actionPrefix) => [
  `${actionPrefix}_START`,
  `${actionPrefix}_SUCCESS`,
  `${actionPrefix}_FAIL`,
];

const generateCRUDActions = (actions) => actions.reduce(
  (crudActions, action) => crudActions.concat(getCRUDActions(action)),
  [],
);

export {
  capitalize,
  underscoreToCamelCase,
  camelToUnderscore,
  formatDollar,
  formatNumber,
  transformJSON,
  generateCRUDActions,
};
