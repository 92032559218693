import apiRequest from './request';

export const getPurchaserOrders = (data) => apiRequest(
  '/api/ordersplaced',
  'GET',
  data,
  null,
  'OAuth',
);

export const getVendorOrders = (data) => apiRequest(
  '/api/ordersreceived',
  'GET',
  data,
  null,
  'OAuth',
);

export const getMonthlyOprSummary = () => apiRequest(
  '/api/home/getmonthlyoprsummary',
  'GET',
  null,
  null,
  'OAuth',
);

export const getTtmOprSummary = () => apiRequest(
  '/api/home/getttmoprsummary',
  'GET',
  null,
  null,
  'OAuth',
);

export const getMonthlyOvrSummary = () => apiRequest(
  '/api/home/getmonthlyovrsummary',
  'GET',
  null,
  null,
  'OAuth',
);

export const getTtmOvrSummary = () => apiRequest(
  '/api/home/getttmovrsummary',
  'GET',
  null,
  null,
  'OAuth',
);

export const getTtmPopStats = () => apiRequest(
  '/api/home/getttmpopstats',
  'GET',
  null,
  null,
  'OAuth',
);

export const getTtmRfpCounts = () => apiRequest(
  '/api/home/getttmrfpcounts',
  'GET',
  null,
  null,
  'OAuth',
);

export const getTtmRfpReferenceTotals = () => apiRequest(
  '/api/home/getttmrfpreferencetotals',
  'GET',
  null,
  null,
  'OAuth',
);

export const getRfpDetails = (orderId) => apiRequest(
  `/api/order/${orderId}`,
  'GET',
  null,
  null,
  'OAuth',
);

export const getOfferValidityList = () => apiRequest(
  '/api/order/offerValidityList',
  'GET',
  null,
  null,
  'OAuth',
);

export const getCurrencyList = () => apiRequest(
  '/api/order/currencyList',
  'GET',
  null,
  null,
  'OAuth',
);

export const createRfp = (data) => apiRequest(
  '/api/order',
  'POST',
  null,
  data,
  'OAuth',
  'multipart/form-data',
);

export const createRfpItem = (data) => apiRequest(
  '/api/orderitem',
  'POST',
  null,
  data,
  'OAuth',
);

export const createBulkRfpItems = (data) => apiRequest(
  '/api/orderitem/ImportItems',
  'POST',
  null,
  data,
  'OAuth',
  'multipart/form-data',
);

export const cloneRfp = (rfpId) => apiRequest(
  `/api/order/clone/${rfpId}`,
  'GET',
  null,
  null,
  'OAuth',
);

export const searchCatalogItems = (searchText) => apiRequest(
  `/api/items?searchtext=${searchText}&limit=50`,
  'GET',
  null,
  null,
  'OAuth',
);

export const saveRfp = (data) => apiRequest(
  '/api/order',
  'PUT',
  null,
  data,
  'OAuth',
  'multipart/form-data',
);

export const deleteRfp = (rfpId) => apiRequest(
  `/api/order/${rfpId}`,
  'DELETE',
  null,
  null,
  'OAuth',
);

export const deleteRfpItem = (itemId) => apiRequest(
  `/api/orderitem/${itemId}`,
  'DELETE',
  null,
  null,
  'OAuth',
);

export const getRfpItem = (itemId) => apiRequest(
  `/api/orderitem/${itemId}`,
  'GET',
  null,
  null,
  'OAuth',
);

export const updateRfpItem = (data) => apiRequest(
  '/api/orderitem',
  'PUT',
  null,
  data,
  'OAuth',
);

export const changeRfpState = (rfpId, newStatus) => apiRequest(
  '/api/order/changeStatus',
  'PUT',
  null,
  {
    RfpId: rfpId,
    StatusId: newStatus,
  },
  'OAuth',
);