import { createActions, handleActions } from 'redux-actions';
import {
  registerNewUser,
  getClientDetails,
  getSpecificClientDetails,
  getDeliveryLocations,
  getCategories,
  getCategoryItems,
  getUnitTypes,
  getIncoTerms,
} from '../api/account';
import { generateCRUDActions } from '../utils';

const initialState = {
  lang: 'en',
  bearerToken: null,
  isBuyer: false,
  isBuyerManager: false,
  isSupplier: false,
  isSupplierManager: false,
  isPurchaser: false,
  isVendor: false,
  loginRequestStatus: {
    loading: false,
    complete: false,
    error: undefined,
  },
  clientDetails: {
    data: null,
    loading: false,
    complete: false,
    error: undefined,
  },
  specificClientDetails: {
    data: null,
    loading: false,
    complete: false,
    error: undefined,
  },
  deliveryLocations: {
    data: null,
    loading: false,
    complete: false,
    error: undefined,
  },
  categories: {
    data: null,
    loading: false,
    complete: false,
    error: undefined,
  },
  categoryItems: {
    data: null,
    loading: false,
    complete: false,
    error: undefined,
  },
  unitTypes: {
    data: null,
    loading: false,
    complete: false,
    error: undefined,
  },
  incoTerms: {
    data: null,
    loading: false,
    complete: false,
    error: undefined,
  },
};

const actions = [
  'SET_LANG',
  'SET_SECTION',
  'CLEAR_CATEGORIES',
  'CLEAR_SPECIFIC_CLIENT_DETAILS',
  ...generateCRUDActions([
    'LOGIN_REQUEST',
    'REGISTER_NEW_USER',
    'GET_CLIENT_DETAILS',
    'GET_SPECIFIC_CLIENT_DETAILS',
    'GET_DELIVERY_LOCATIONS',
    'GET_CATEGORIES',
    'GET_CATEGORY_ITEMS',
    'GET_UNIT_TYPES',
    'GET_INCO_TERMS',
  ]),
];

const actionCreators = createActions({}, ...actions, { prefix: 'ACCOUNT' });

const {
  setLang,
  setSection,
  clearCategories,
  clearSpecificClientDetails,
  loginRequestStart,
  loginRequestSuccess,
  loginRequestFail,
  registerNewUserStart,
  registerNewUserSuccess,
  registerNewUserFail,
  getClientDetailsStart,
  getSpecificClientDetailsSuccess,
  getSpecificClientDetailsFail,
  getSpecificClientDetailsStart,
  getClientDetailsSuccess,
  getClientDetailsFail,
  getDeliveryLocationsStart,
  getDeliveryLocationsSuccess,
  getDeliveryLocationsFail,
  getCategoriesStart,
  getCategoriesSuccess,
  getCategoriesFail,
  getCategoryItemsStart,
  getCategoryItemsSuccess,
  getCategoryItemsFail,
  getUnitTypesStart,
  getUnitTypesSuccess,
  getUnitTypesFail,
  getIncoTermsStart,
  getIncoTermsSuccess,
  getIncoTermsFail,
} = actionCreators;

const thunks = {
  loginRequest: (username, password) => (dispatch) => {
    dispatch(loginRequestStart());

    const requestParams = {
      grant_type: 'password',
      username,
      password,
    }
  
    const formData = Object.keys(requestParams).map(
      key => `${encodeURIComponent(key)}=${encodeURIComponent(requestParams[key])}`,
    ).join('&');
  
    return fetch('https://dev.ronesoft.com/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: formData
    }).then(response => response.json())
    .then(data => {
      console.log('Login Data : ', data);
      if (data.error) {
        dispatch(loginRequestFail('Login Failed'));
      } else {
        dispatch(loginRequestSuccess(data));
      }
    })
    .catch((err) => {
      dispatch(loginRequestFail(err));
    });
  },

  registerNewUser: (username, email, password, recaptcha) => (dispatch) => {
    dispatch(registerNewUserStart());

    return registerNewUser(username, email, password, recaptcha)
      .then((data) => {
        dispatch(registerNewUserSuccess(data));
        return data;
      })
      .catch((err) => {
        dispatch(registerNewUserFail(err));
        throw err;
      });
  },

  getClientDetails: () => (dispatch) => {
    dispatch(getClientDetailsStart());

    return getClientDetails()
      .then((data) => {
        dispatch(getClientDetailsSuccess(data));
        return data;
      })
      .catch((err) => {
        dispatch(getClientDetailsFail(err));
        throw err;
      });
  },

  getSpecificClientDetails: (clientId) => (dispatch) => {
    dispatch(getSpecificClientDetailsStart());

    return getSpecificClientDetails(clientId)
      .then((data) => {
        dispatch(getSpecificClientDetailsSuccess(data));
        return data;
      })
      .catch((err) => {
        dispatch(getSpecificClientDetailsFail(err));
        throw err;
      });
  },

  getDeliveryLocations: () => (dispatch) => {
    dispatch(getDeliveryLocationsStart());

    return getDeliveryLocations()
      .then((data) => {
        dispatch(getDeliveryLocationsSuccess(data));
        return data;
      })
      .catch((err) => {
        dispatch(getDeliveryLocationsFail(err));
        throw err;
      });
  },

  getCategories: () => (dispatch) => {
    dispatch(getCategoriesStart());

    return getCategories()
      .then((data) => {
        dispatch(getCategoriesSuccess(data));
        return data;
      })
      .catch((err) => {
        dispatch(getCategoriesFail(err));
        throw err;
      });
  },
  
  getCategoryItems: (data) => (dispatch) => {
    dispatch(getCategoryItemsStart());

    console.log('datadatadata : ', data);

    return getCategoryItems(data)
      .then((resp) => {
        dispatch(getCategoryItemsSuccess(resp));
        return resp;
      })
      .catch((err) => {
        dispatch(getCategoryItemsFail(err));
        throw err;
      });
  },

  getUnitTypes: (data) => (dispatch) => {
    dispatch(getUnitTypesStart());

    return getUnitTypes(data)
      .then((resp) => {
        dispatch(getUnitTypesSuccess(resp));
        return resp;
      })
      .catch((err) => {
        dispatch(getUnitTypesFail(err));
        throw err;
      });
  },

  getIncoTerms: () => (dispatch) => {
    dispatch(getIncoTermsStart());

    return getIncoTerms()
      .then((data) => {
        dispatch(getIncoTermsSuccess(data));
        return data;
      })
      .catch((err) => {
        dispatch(getIncoTermsFail(err));
        throw err;
      });
  },
};

const reducer = handleActions(
  {
    [setLang]: (state, { payload }) => ({ ...state, lang: payload }),

    [setSection]: (state, { payload }) => {
      console.log('APUYUL:dsfv : ', payload);
      return ({ ...state, isPurchaser: payload.isPurchaser, isVendor: payload.isVendor });
    },

    [clearCategories]: (state) => ({ 
      ...state,
      categories: {
        data: null,
        loading: false,
        complete: false,
        error: undefined,
      },
      categoryItems: {
        data: null,
        loading: false,
        complete: false,
        error: undefined,
      },
    }),

    [clearSpecificClientDetails]: (state) => ({ 
      ...state, 
      specificClientDetails: {
        data: null,
        loading: false,
        complete: false,
        error: undefined,
      }
    }),

    [loginRequestStart]: (state) => ({
      ...state,
      bearerToken: undefined,
      isBuyer: false,
      isBuyerManager: false,
      isSupplier: false,
      isSupplierManager:  false,
      loginRequestStatus: {
        loading: true,
        complete: false,
        error: undefined,
      },
    }),

    [loginRequestSuccess]: (state, { payload }) => {
      const roles = payload.roles.split(',');
      return {
        ...state,
        bearerToken: payload.access_token,
        isBuyer: roles.includes('Buyer'),
        isBuyerManager: roles.includes('Buyer Manager'),
        isSupplier: roles.includes('Supplier'),
        isSupplierManager: roles.includes('SupplierManager'),
        loginRequestStatus: {
          loading: false,
          complete: true,
          error: undefined,
        },
      }
    },

    [loginRequestFail]: (state, errorObj) => ({
      ...state,
      bearerToken: undefined,
      loginRequestStatus: {
        loading: false,
        complete: true,
        error: errorObj,
      },
    }),

    [registerNewUserStart]: (state) => ({
      ...state,
      bearerToken: undefined,
      userRegistration: {
        ...state.userRegistration,
        loading: true,
        complete: false,
        error: undefined,
      },
    }),

    [registerNewUserSuccess]: (state, { payload }) => ({
      ...state,
      deliveryLocations: {
        data: null,
        loading: false,
        complete: false,
        error: undefined,
      },
    }),

    [registerNewUserFail]: (state, errorObj) => ({
      ...state,
      bearerToken: undefined,
      userRegistration: {
        ...state.userRegistration,
        loading: false,
        complete: true,
        error: errorObj.payload.error,
      },
    }),

    [getClientDetailsStart]: (state) => ({
      ...state,
      clientDetails: {
        data: null,
        loading: true,
        complete: false,
        error: undefined,
      },
    }),

    [getClientDetailsSuccess]: (state, { payload }) => ({
      ...state,
      clientDetails: {
        data: payload,
        loading: false,
        complete: true,
        error: undefined,
      },
    }),

    [getClientDetailsFail]: (state, errorObj) => ({
      ...state,
      clientDetails: {
        data: null,
        loading: false,
        complete: true,
        error: errorObj,
      },
    }),

    [getSpecificClientDetailsStart]: (state) => ({
      ...state,
      specificClientDetails: {
        data: null,
        loading: true,
        complete: false,
        error: undefined,
      },
    }),

    [getSpecificClientDetailsSuccess]: (state, { payload }) => ({
      ...state,
      specificClientDetails: {
        data: payload,
        loading: false,
        complete: true,
        error: undefined,
      },
    }),

    [getSpecificClientDetailsFail]: (state, errorObj) => ({
      ...state,
      specificClientDetails: {
        data: null,
        loading: false,
        complete: true,
        error: errorObj,
      },
    }),

    [getDeliveryLocationsStart]: (state) => ({
      ...state,
      deliveryLocations: {
        data: null,
        loading: true,
        complete: false,
        error: undefined,
      },
    }),

    [getDeliveryLocationsSuccess]: (state, { payload }) => ({
      ...state,
      deliveryLocations: {
        data: payload,
        loading: false,
        complete: true,
        error: undefined,
      },
    }),

    [getDeliveryLocationsFail]: (state, errorObj) => ({
      ...state,
      deliveryLocations: {
        data: null,
        loading: false,
        complete: true,
        error: errorObj,
      },
    }),

    [getCategoriesStart]: (state) => ({
      ...state,
      categories: {
        data: null,
        loading: true,
        complete: false,
        error: undefined,
      },
    }),

    [getCategoriesSuccess]: (state, { payload }) => ({
      ...state,
      categories: {
        data: payload,
        loading: false,
        complete: true,
        error: undefined,
      },
    }),

    [getCategoriesFail]: (state, errorObj) => ({
      ...state,
      categories: {
        data: null,
        loading: false,
        complete: true,
        error: errorObj,
      },
    }),

    [getCategoryItemsStart]: (state) => ({
      ...state,
      
      categoryItems: {
        ...state.categoryItems,
        loading: true,
        complete: false,
        error: undefined,
      },
    }),

    [getCategoryItemsSuccess]: (state, { payload }) => ({
      ...state,
      categoryItems: {
        data: payload,
        loading: false,
        complete: true,
        error: undefined,
      },
    }),

    [getCategoryItemsFail]: (state, errorObj) => ({
      ...state,
      categoryItems: {
        data: null,
        loading: false,
        complete: true,
        error: errorObj,
      },
    }),

    [getUnitTypesStart]: (state) => ({
      ...state,
      unitTypes: {
        data: null,
        loading: true,
        complete: false,
        error: undefined,
      },
    }),

    [getUnitTypesSuccess]: (state, { payload }) => ({
      ...state,
      unitTypes: {
        data: payload,
        loading: false,
        complete: true,
        error: undefined,
      },
    }),

    [getUnitTypesFail]: (state, errorObj) => ({
      ...state,
      unitTypes: {
        data: null,
        loading: false,
        complete: true,
        error: errorObj,
      },
    }),

    [getIncoTermsStart]: (state) => ({
      ...state,
      incoTerms: {
        data: null,
        loading: true,
        complete: false,
        error: undefined,
      },
    }),

    [getIncoTermsSuccess]: (state, { payload }) => ({
      ...state,
      incoTerms: {
        data: payload,
        loading: false,
        complete: true,
        error: undefined,
      },
    }),

    [getIncoTermsFail]: (state, errorObj) => ({
      ...state,
      incoTerms: {
        data: null,
        loading: false,
        complete: true,
        error: errorObj,
      },
    }),
  },
  initialState,
);

export default {
  actionCreators: { ...actionCreators, ...thunks },
  reducer,
};
