import { createActions, handleActions } from 'redux-actions';
import {
  getPurchaserOrders,
  getVendorOrders,
  getMonthlyOprSummary,
  getTtmOprSummary,
  getMonthlyOvrSummary,
  getTtmOvrSummary,
  getTtmPopStats,
  getTtmRfpCounts,
  getTtmRfpReferenceTotals,
  getRfpDetails,
  getOfferValidityList,
  getCurrencyList,
  createRfp,
  createRfpItem,
  saveRfp,
  createBulkRfpItems,
  cloneRfp,
  searchCatalogItems,
  deleteRfp,
  deleteRfpItem,
  getRfpItem,
  updateRfpItem,
  changeRfpState,
 } from '../api/orders';
import { generateCRUDActions } from '../utils';

const initialState = {
  purchaserOrders: {
    data: null,
    loading: false,
    complete: false,
    error: undefined,
  },
  vendorOrders: {
    data: null,
    loading: false,
    complete: false,
    error: undefined,
  },
  monthlyOprSummary: {
    data: null,
    loading: false,
    complete: false,
    error: undefined,
  },
  ttmOprSummary: {
    data: null,
    loading: false,
    complete: false,
    error: undefined,
  },
  monthlyOvrSummary: {
    data: null,
    loading: false,
    complete: false,
    error: undefined,
  },
  ttmOvrSummary: {
    data: null,
    loading: false,
    complete: false,
    error: undefined,
  },
  ttmPopStats: {
    data: null,
    loading: false,
    complete: false,
    error: undefined,
  },
  ttmRfpCounts: {
    data: null,
    loading: false,
    complete: false,
    error: undefined,
  },
  ttmRfpReferenceTotals: {
    data: null,
    loading: false,
    complete: false,
    error: undefined,
  },
  rfpDetails: {
    data: null,
    loading: false,
    complete: false,
    error: undefined,
  },
  offerValidityList: {
    data: null,
    loading: false,
    complete: false,
    error: undefined,
  },
  currencyList: {
    data: null,
    loading: false,
    complete: false,
    error: undefined,
  },
  createRfpStatus: {
    data: null,
    loading: false,
    complete: false,
    error: undefined,
  },
  createRfpItemStatus: {
    data: null,
    loading: false,
    complete: false,
    error: undefined,
  },
  createBulkRfpItemsStatus: {
    data: null,
    loading: false,
    complete: false,
    error: undefined,
  },
  cloneRfpStatus: {
    data: null,
    loading: false,
    complete: false,
    error: undefined,
  },
  searchCatalogItemsStatus: {
    data: null,
    loading: false,
    complete: false,
    error: undefined,
  },
  saveRfpStatus: {
    data: null,
    loading: false,
    complete: false,
    error: undefined,
  },
  deleteRfpStatus: {
    data: null,
    loading: false,
    complete: false,
    error: undefined,
  },
  deleteRfpItemStatus: {
    data: null,
    loading: false,
    complete: false,
    error: undefined,
  },
  rfpItemDetails: {
    data: null,
    loading: false,
    complete: false,
    error: undefined,
  },
  updateRfpItemStatus: {
    data: null,
    loading: false,
    complete: false,
    error: undefined,
  },
  changeRfpStateStatus: {
    data: null,
    loading: false,
    complete: false,
    error: undefined,
  },
};

const actions = [
  'SET_LANG',
  'CLEAR_RFP_DATA',
  'CLEAR_RFP_STATUS',
  'CLEAR_CREATE_RFP_ITEM_STATUS',
  'CLEAR_RFP_ITEM_DETAILS',
  'CLEAR_BULK_RFP_ITEMS_STATUS',
  'CLEAR_CLONE_RFP_STATUS',
  'CLEAR_SEARCH_CATALOG_ITEMS_STATUS',
  'CLEAR_CHANGE_RFP_STATE_STATUS',
  'CLEAR_UPDATE_RFP_ITEM_STATUS',
  ...generateCRUDActions([
    'GET_PURCHASER_ORDERS',
    'GET_VENDOR_ORDERS',
    'GET_MONTHLY_OPR_SUMMARY',
    'GET_TTM_OPR_SUMMARY',
    'GET_MONTHLY_OVR_SUMMARY',
    'GET_TTM_OVR_SUMMARY',
    'GET_TTM_POP_STATS',
    'GET_TTM_Rfp_COUNTS',
    'GET_TTM_Rfp_REFERENCE_TOTALS',
    'GET_RFP_DETAILS',
    'GET_OFFER_VALIDITY_LIST',
    'GET_CURRENCY_LIST',
    'CREATE_RFP',
    'CREATE_RFP_ITEM',
    'CREATE_BULK_RFP_ITEMS',
    'CLONE_RFP',
    'SEARCH_CATALOG_ITEMS',
    'SAVE_RFP',
    'DELETE_RFP',
    'DELETE_RFP_ITEM',
    'GET_RFP_ITEM',
    'UPDATE_RFP_ITEM',
    'CHANGE_RFP_STATE',
  ]),
];

const actionCreators = createActions({}, ...actions, { prefix: 'ACCOUNT' });

const {
  setLang,
  clearRfpData,
  clearRfpStatus,
  clearCreateRfpItemStatus,
  clearRfpItemDetails,
  clearBulkRfpItemsStatus,
  clearCloneRfpStatus,
  clearSearchCatalogItemsStatus,
  clearChangeRfpStateStatus,
  clearUpdateRfpItemStatus,
  getPurchaserOrdersStart,
  getPurchaserOrdersSuccess,
  getPurchaserOrdersFail,
  getVendorOrdersStart,
  getVendorOrdersSuccess,
  getVendorOrdersFail,
  getMonthlyOprSummaryStart,
  getMonthlyOprSummarySuccess,
  getMonthlyOprSummaryFail,
  getTtmOprSummaryStart,
  getTtmOprSummarySuccess,
  getTtmOprSummaryFail,
  getMonthlyOvrSummaryStart,
  getMonthlyOvrSummarySuccess,
  getMonthlyOvrSummaryFail,
  getTtmOvrSummaryStart,
  getTtmOvrSummarySuccess,
  getTtmOvrSummaryFail,
  getTtmPopStatsStart,
  getTtmPopStatsSuccess,
  getTtmPopStatsFail,
  getTtmRfpCountsStart,
  getTtmRfpCountsSuccess,
  getTtmRfpCountsFail,
  getTtmRfpReferenceTotalsStart,
  getTtmRfpReferenceTotalsSuccess,
  getTtmRfpReferenceTotalsFail,
  getRfpDetailsStart,
  getRfpDetailsSuccess,
  getRfpDetailsFail,
  getOfferValidityListStart,
  getOfferValidityListSuccess,
  getOfferValidityListFail,
  getCurrencyListStart,
  getCurrencyListSuccess,
  getCurrencyListFail,
  createRfpStart,
  createRfpSuccess,
  createRfpFail,
  createRfpItemStart,
  createRfpItemSuccess,
  createRfpItemFail,
  createBulkRfpItemsStart,
  createBulkRfpItemsSuccess,
  createBulkRfpItemsFail,
  cloneRfpStart,
  cloneRfpSuccess,
  cloneRfpFail,
  searchCatalogItemsStart,
  searchCatalogItemsSuccess,
  searchCatalogItemsFail,
  saveRfpStart,
  saveRfpSuccess,
  saveRfpFail,
  deleteRfpStart,
  deleteRfpSuccess,
  deleteRfpFail,
  deleteRfpItemStart,
  deleteRfpItemSuccess,
  deleteRfpItemFail,
  getRfpItemStart,
  getRfpItemSuccess,
  getRfpItemFail,
  updateRfpItemStart,
  updateRfpItemSuccess,
  updateRfpItemFail,
  changeRfpStateStart,
  changeRfpStateSuccess,
  changeRfpStateFail,
} = actionCreators;

const thunks = {
  getPurchaserOrders: (orderInfo) => (dispatch) => {
    dispatch(getPurchaserOrdersStart());
    return getPurchaserOrders(orderInfo)
      .then((data) => {
        data.status = orderInfo.status
        dispatch(getPurchaserOrdersSuccess(data));
        return data;
      })
      .catch((err) => {
        dispatch(getPurchaserOrdersFail(err));
        throw err;
      });
  },
  getVendorOrders: (orderInfo) => (dispatch) => {
    dispatch(getVendorOrdersStart());
    return getVendorOrders(orderInfo)
      .then((data) => {
        data.status = orderInfo.status
        dispatch(getVendorOrdersSuccess(data));
        return data;
      })
      .catch((err) => {
        dispatch(getVendorOrdersFail(err));
        throw err;
      });
  },
  getMonthlyOprSummary: () => (dispatch) => {
    console.log('getMonthlyOprSummary');
    dispatch(getMonthlyOprSummaryStart());
    return getMonthlyOprSummary()
      .then((data) => {
        console.log('DATA : ', data);
        dispatch(getMonthlyOprSummarySuccess(data));
        return data;
      })
      .catch((err) => {
        console.log('ERROR : ', err);
        dispatch(getMonthlyOprSummaryFail(err));
        throw err;
      });
  },

  getTtmOprSummary: () => (dispatch) => {
    console.log('getTtmOprSummary');
    dispatch(getTtmOprSummaryStart());
    return getTtmOprSummary()
      .then((data) => {
        console.log('DATA : ', data);
        dispatch(getTtmOprSummarySuccess(data));
        return data;
      })
      .catch((err) => {
        console.log('ERROR : ', err);
        dispatch(getTtmOprSummaryFail(err));
        throw err;
      });
  },
  getMonthlyOvrSummary: () => (dispatch) => {
    console.log('getMonthlyOvrSummary');
    dispatch(getMonthlyOvrSummaryStart());
    return getMonthlyOvrSummary()
      .then((data) => {
        console.log('DATA : ', data);
        dispatch(getMonthlyOvrSummarySuccess(data));
        return data;
      })
      .catch((err) => {
        console.log('ERROR : ', err);
        dispatch(getMonthlyOvrSummaryFail(err));
        throw err;
      });
  },
  getTtmOvrSummary: () => (dispatch) => {
    console.log('getTtmOvrSummary');
    dispatch(getTtmOvrSummaryStart());
    return getTtmOvrSummary()
      .then((data) => {
        console.log('DATA : ', data);
        dispatch(getTtmOvrSummarySuccess(data));
        return data;
      })
      .catch((err) => {
        console.log('ERROR : ', err);
        dispatch(getTtmOvrSummaryFail(err));
        throw err;
      });
  },
  getTtmPopStats: () => (dispatch) => {
    console.log('getTtmPopStats');
    dispatch(getTtmPopStatsStart());
    return getTtmPopStats()
      .then((data) => {
        console.log('DATA : ', data);
        dispatch(getTtmPopStatsSuccess(data));
        return data;
      })
      .catch((err) => {
        console.log('ERROR : ', err);
        dispatch(getTtmPopStatsFail(err));
        throw err;
      });
  },
  getTtmRfpCounts: () => (dispatch) => {
    console.log('getTtmRfpCounts');
    dispatch(getTtmRfpCountsStart());
    return getTtmRfpCounts()
      .then((data) => {
        console.log('DATA : ', data);
        dispatch(getTtmRfpCountsSuccess(data));
        return data;
      })
      .catch((err) => {
        console.log('ERROR : ', err);
        dispatch(getTtmRfpCountsFail(err));
        throw err;
      });
  },
  getTtmRfpReferenceTotals: () => (dispatch) => {
    console.log('getTtmRfpReferenceTotals');
    dispatch(getTtmRfpReferenceTotalsStart());
    return getTtmRfpReferenceTotals()
      .then((data) => {
        console.log('DATA : ', data);
        dispatch(getTtmRfpReferenceTotalsSuccess(data));
        return data;
      })
      .catch((err) => {
        console.log('ERROR : ', err);
        dispatch(getTtmRfpReferenceTotalsFail(err));
        throw err;
      });
  },

  getRfpDetails: (orderId) => (dispatch) => {
    console.log('getRfpDetails : ', orderId);
    dispatch(getRfpDetailsStart());
    return getRfpDetails(orderId)
      .then((data) => {
        console.log('DATA : ', data);
        dispatch(getRfpDetailsSuccess(data));
        return data;
      })
      .catch((err) => {
        console.log('ERROR : ', err);
        dispatch(getRfpDetailsFail(err));
        throw err;
      });
  },
  getOfferValidityList: () => (dispatch) => {
    console.log('getOfferValidityList');
    dispatch(getOfferValidityListStart());
    return getOfferValidityList()
      .then((data) => {
        console.log('DATA : ', data);
        dispatch(getOfferValidityListSuccess(data));
        return data;
      })
      .catch((err) => {
        console.log('ERROR : ', err);
        dispatch(getOfferValidityListFail(err));
        throw err;
      });
  },
  getCurrencyList: () => (dispatch) => {
    console.log('getCurrencyList');
    dispatch(getCurrencyListStart());
    return getCurrencyList()
      .then((data) => {
        console.log('DATA : ', data);
        dispatch(getCurrencyListSuccess(data));
        return data;
      })
      .catch((err) => {
        console.log('ERROR : ', err);
        dispatch(getCurrencyListFail(err));
        throw err;
      });
  },
  createRfp: (data) => (dispatch) => {
    console.log('createRfp : ', data);
    dispatch(createRfpStart());
    return createRfp(data)
      .then((resp) => {
        console.log('resp : ', resp);
        dispatch(createRfpSuccess(resp));
        return resp;
      })
      .catch((err) => {
        console.log('ERROR : ', err);
        dispatch(createRfpFail(err));
        throw err;
      });
  },
  createRfpItem: (data) => (dispatch) => {
    console.log('createRfpItem : ', data);
    dispatch(createRfpItemStart());
    return createRfpItem(data)
      .then((resp) => {
        console.log('resp : ', resp);
        dispatch(createRfpItemSuccess(resp));
        return resp;
      })
      .catch((err) => {
        console.log('ERROR : ', err);
        dispatch(createRfpItemFail(err));
        throw err;
      });
  },
  createBulkRfpItems: (data) => (dispatch) => {
    console.log('createBulkRfpItems : ', data);
    dispatch(createBulkRfpItemsStart());
    return createBulkRfpItems(data)
      .then((resp) => {
        console.log('resp : ', resp);
        dispatch(createBulkRfpItemsSuccess(resp));
        return resp;
      })
      .catch((err) => {
        console.log('ERROR : ', err);
        dispatch(createBulkRfpItemsFail(err));
        throw err;
      });
  },
  cloneRfp: (rfpId) => (dispatch) => {
    console.log('cloneRfp : ', rfpId);
    dispatch(cloneRfpStart());
    return cloneRfp(rfpId)
      .then((resp) => {
        console.log('resp : ', resp);
        dispatch(cloneRfpSuccess(resp));
        return resp;
      })
      .catch((err) => {
        console.log('ERROR : ', err);
        dispatch(cloneRfpFail(err));
        throw err;
      });
  },
  searchCatalogItems: (searchText) => (dispatch) => {
    console.log('searchCatalogItems : ', searchText);
    dispatch(searchCatalogItemsStart());
    return searchCatalogItems(searchText)
      .then((resp) => {
        console.log('resp : ', resp);
        dispatch(searchCatalogItemsSuccess(resp));
        return resp;
      })
      .catch((err) => {
        console.log('ERROR : ', err);
        dispatch(searchCatalogItemsFail(err));
        throw err;
      });
  },
  saveRfp: (searchText) => (dispatch) => {
    console.log('saveRfp : ', searchText);
    dispatch(saveRfpStart());
    return saveRfp(searchText)
      .then((resp) => {
        console.log('resp : ', resp);
        dispatch(saveRfpSuccess(resp));
        return resp;
      })
      .catch((err) => {
        console.log('ERROR : ', err);
        dispatch(saveRfpFail(err));
        throw err;
      });
  },
  deleteRfp: (rfpId) => (dispatch) => {
    console.log('deleteRfp : ', rfpId);
    dispatch(deleteRfpStart());
    return deleteRfp(rfpId)
      .then((resp) => {
        console.log('resp : ', resp);
        dispatch(deleteRfpSuccess(resp));
        return resp;
      })
      .catch((err) => {
        console.log('ERROR : ', err);
        dispatch(deleteRfpFail(err));
        throw err;
      });
  },
  deleteRfpItem: (itemId) => (dispatch) => {
    console.log('deleteRfpItem : ', itemId);
    dispatch(deleteRfpItemStart());
    return deleteRfpItem(itemId)
      .then((resp) => {
        console.log('resp : ', resp);
        dispatch(deleteRfpItemSuccess(resp));
        return resp;
      })
      .catch((err) => {
        console.log('ERROR : ', err);
        dispatch(deleteRfpItemFail(err));
        throw err;
      });
  },
  getRfpItem: (itemId) => (dispatch) => {
    console.log('getRfpItem : ', itemId);
    dispatch(getRfpItemStart());
    return getRfpItem(itemId)
      .then((resp) => {
        console.log('resp : ', resp);
        dispatch(getRfpItemSuccess(resp));
        return resp;
      })
      .catch((err) => {
        console.log('ERROR : ', err);
        dispatch(getRfpItemFail(err));
        throw err;
      });
  },
  updateRfpItem: (data) => (dispatch) => {
    console.log('updateRfpItem : ', data);
    dispatch(updateRfpItemStart());
    return updateRfpItem(data)
      .then((resp) => {
        console.log('resp : ', resp);
        dispatch(updateRfpItemSuccess(resp));
        return resp;
      })
      .catch((err) => {
        console.log('ERROR : ', err);
        dispatch(updateRfpItemFail(err));
        throw err;
      });
  },
  changeRfpState: (rfpId, newState) => (dispatch) => {
    console.log('changeRfpState : ', rfpId, newState);
    dispatch(changeRfpStateStart());
    return changeRfpState(rfpId, newState)
      .then((resp) => {
        console.log('resp : ', resp);
        dispatch(changeRfpStateSuccess(resp));
        return resp;
      })
      .catch((err) => {
        console.log('ERROR : ', err);
        dispatch(changeRfpStateFail(err));
        throw err;
      });
  },
};

const reducer = handleActions(
  {
    [setLang]: (state, { payload }) => ({ ...state, lang: payload }),

    [clearRfpData]: (state) => ({ 
      ...state,
      rfpDetails: {
        data: null,
        loading: false,
        complete: false,
        error: undefined,
      },
    }),

    [clearRfpStatus]: (state) => ({ 
      ...state,
      createRfpStatus: {
        data: null,
        loading: false,
        complete: false,
        error: undefined,
      },
    }),

    [clearCreateRfpItemStatus]: (state) => ({ 
      ...state,
      createRfpItemStatus: {
        data: null,
        loading: false,
        complete: false,
        error: undefined,
      }
    }),

    [clearRfpItemDetails]: (state) => ({ 
      ...state,
      rfpItemDetails: {
        data: null,
        loading: false,
        complete: false,
        error: undefined,
      },
    }),

    [clearBulkRfpItemsStatus]: (state) => ({ 
      ...state,
      createBulkRfpItemsStatus: {
        data: null,
        loading: false,
        complete: false,
        error: undefined,
      }
    }),

    [clearCloneRfpStatus]: (state) => ({ 
      ...state,
      cloneRfpStatus: {
        data: null,
        loading: false,
        complete: false,
        error: undefined,
      }
    }),

    [clearSearchCatalogItemsStatus]: (state) => ({ 
      ...state,
      searchCatalogItemsStatus: {
        data: null,
        loading: false,
        complete: false,
        error: undefined,
      }
    }),

    [clearChangeRfpStateStatus]: (state) => ({ 
      ...state,
      changeRfpStateStatus: {
        data: null,
        loading: false,
        complete: false,
        error: undefined,
      },
    }),

    [clearUpdateRfpItemStatus]: (state) => ({ 
      ...state,
      updateRfpItemStatus: {
        data: null,
        loading: false,
        complete: false,
        error: undefined,
      },
    }),

    [getPurchaserOrdersStart]: (state) => ({
      ...state,
      purchaserOrders: {
        ...state.purchaserOrders,
        loading: true,
        complete: false,
        error: undefined,
      },
    }),

    [getPurchaserOrdersSuccess]: (state, { payload }) => ({
      ...state,
      purchaserOrders: {
        data: payload,
        loading: false,
        complete: true,
        error: undefined,
      },
    }),

    [getPurchaserOrdersFail]: (state, errorObj) => ({
      ...state,
      purchaserOrders: {
        ...state.purchaserOrders,
        loading: false,
        complete: true,
        error: errorObj,
      },
    }),

    [getVendorOrdersStart]: (state) => ({
      ...state,
      vendorOrders: {
        ...state.vendorOrders,
        loading: true,
        complete: false,
        error: undefined,
      },
    }),

    [getVendorOrdersSuccess]: (state, { payload }) => ({
      ...state,
      vendorOrders: {
        data: payload,
        loading: false,
        complete: true,
        error: undefined,
      },
    }),

    [getVendorOrdersFail]: (state, errorObj) => ({
      ...state,
      vendorOrders: {
        ...state.vendorOrders,
        loading: false,
        complete: true,
        error: errorObj,
      },
    }),

    [getMonthlyOprSummaryStart]: (state) => ({
      ...state,
      monthlyOprSummary: {
        ...state.monthlyOprSummary,
        loading: true,
        complete: false,
        error: undefined,
      },
    }),

    [getMonthlyOprSummarySuccess]: (state, { payload }) => ({
      ...state,
      monthlyOprSummary: {
        data: payload,
        loading: false,
        complete: true,
        error: undefined,
      },
    }),

    [getMonthlyOprSummaryFail]: (state, errorObj) => ({
      ...state,
      monthlyOprSummary: {
        ...state.monthlyOprSummary,
        loading: false,
        complete: true,
        error: errorObj,
      },
    }),

    [getTtmOprSummaryStart]: (state) => ({
      ...state,
      ttmOprSummary: {
        ...state.ttmOprSummary,
        loading: true,
        complete: false,
        error: undefined,
      },
    }),

    [getTtmOprSummarySuccess]: (state, { payload }) => ({
      ...state,
      ttmOprSummary: {
        data: payload,
        loading: false,
        complete: true,
        error: undefined,
      },
    }),

    [getTtmOprSummaryFail]: (state, errorObj) => ({
      ...state,
      ttmOprSummary: {
        ...state.ttmOprSummary,
        loading: false,
        complete: true,
        error: errorObj,
      },
    }),

    [getMonthlyOvrSummaryStart]: (state) => ({
      ...state,
      monthlyOvrSummary: {
        ...state.monthlyOvrSummary,
        loading: true,
        complete: false,
        error: undefined,
      },
    }),

    [getMonthlyOvrSummarySuccess]: (state, { payload }) => ({
      ...state,
      monthlyOvrSummary: {
        data: payload,
        loading: false,
        complete: true,
        error: undefined,
      },
    }),

    [getMonthlyOvrSummaryFail]: (state, errorObj) => ({
      ...state,
      monthlyOvrSummary: {
        ...state.monthlyOvrSummary,
        loading: false,
        complete: true,
        error: errorObj,
      },
    }),

    [getTtmOvrSummaryStart]: (state) => ({
      ...state,
      ttmOvrSummary: {
        ...state.ttmOvrSummary,
        loading: true,
        complete: false,
        error: undefined,
      },
    }),

    [getTtmOvrSummarySuccess]: (state, { payload }) => ({
      ...state,
      ttmOvrSummary: {
        data: payload,
        loading: false,
        complete: true,
        error: undefined,
      },
    }),

    [getTtmOvrSummaryFail]: (state, errorObj) => ({
      ...state,
      ttmOvrSummary: {
        ...state.ttmOvrSummary,
        loading: false,
        complete: true,
        error: errorObj,
      },
    }),

    [getTtmPopStatsStart]: (state) => ({
      ...state,
      ttmPopStats: {
        ...state.ttmPopStats,
        loading: true,
        complete: false,
        error: undefined,
      },
    }),

    [getTtmPopStatsSuccess]: (state, { payload }) => ({
      ...state,
      ttmPopStats: {
        data: payload,
        loading: false,
        complete: true,
        error: undefined,
      },
    }),

    [getTtmRfpCountsFail]: (state, errorObj) => ({
      ...state,
      ttmPopStats: {
        ...state.ttmPopStats,
        loading: false,
        complete: true,
        error: errorObj,
      },
    }),

    [getTtmRfpCountsStart]: (state) => ({
      ...state,
      ttmRfpCounts: {
        ...state.ttmRfpCounts,
        loading: true,
        complete: false,
        error: undefined,
      },
    }),

    [getTtmRfpCountsSuccess]: (state, { payload }) => ({
      ...state,
      ttmRfpCounts: {
        data: payload,
        loading: false,
        complete: true,
        error: undefined,
      },
    }),

    [getTtmPopStatsFail]: (state, errorObj) => ({
      ...state,
      ttmRfpCounts: {
        ...state.ttmRfpCounts,
        loading: false,
        complete: true,
        error: errorObj,
      },
    }),

    [getTtmRfpReferenceTotalsStart]: (state) => ({
      ...state,
      ttmRfpReferenceTotals: {
        ...state.ttmRfpReferenceTotals,
        loading: true,
        complete: false,
        error: undefined,
      },
    }),

    [getTtmRfpReferenceTotalsSuccess]: (state, { payload }) => ({
      ...state,
      ttmRfpReferenceTotals: {
        data: payload,
        loading: false,
        complete: true,
        error: undefined,
      },
    }),

    [getTtmRfpReferenceTotalsFail]: (state, errorObj) => ({
      ...state,
      ttmRfpReferenceTotals: {
        ...state.ttmRfpReferenceTotals,
        loading: false,
        complete: true,
        error: errorObj,
      },
    }),

    [getRfpDetailsStart]: (state) => ({
      ...state,
      rfpDetails: {
        ...state.rfpDetails,
        loading: true,
        complete: false,
        error: undefined,
      },
    }),

    [getRfpDetailsSuccess]: (state, { payload }) => ({
      ...state,
      rfpDetails: {
        data: payload,
        loading: false,
        complete: true,
        error: undefined,
      },
    }),

    [getRfpDetailsFail]: (state, errorObj) => ({
      ...state,
      rfpDetails: {
        ...state.rfpDetails,
        loading: false,
        complete: true,
        error: errorObj,
      },
    }),

    [getOfferValidityListStart]: (state) => ({
      ...state,
      offerValidityList: {
        ...state.offerValidityList,
        loading: true,
        complete: false,
        error: undefined,
      },
    }),

    [getOfferValidityListSuccess]: (state, { payload }) => ({
      ...state,
      offerValidityList: {
        data: payload,
        loading: false,
        complete: true,
        error: undefined,
      },
    }),

    [getOfferValidityListFail]: (state, errorObj) => ({
      ...state,
      offerValidityList: {
        ...state.offerValidityList,
        loading: false,
        complete: true,
        error: errorObj,
      },
    }),

    [getCurrencyListStart]: (state) => ({
      ...state,
      currencyList: {
        ...state.currencyList,
        loading: true,
        complete: false,
        error: undefined,
      },
    }),

    [getCurrencyListSuccess]: (state, { payload }) => ({
      ...state,
      currencyList: {
        data: payload,
        loading: false,
        complete: true,
        error: undefined,
      },
    }),

    [getCurrencyListFail]: (state, errorObj) => ({
      ...state,
      currencyList: {
        data: null,
        loading: false,
        complete: true,
        error: errorObj,
      },
    }),

    [createRfpStart]: (state) => ({
      ...state,
      createRfpStatus: {
        ...state.createRfpStatus,
        loading: true,
        complete: false,
        error: undefined,
      },
    }),

    [createRfpSuccess]: (state, { payload }) => ({
      ...state,
      createRfpStatus: {
        data: payload,
        loading: false,
        complete: true,
        error: undefined,
      },
    }),

    [createRfpFail]: (state, errorObj) => ({
      ...state,
      createRfpStatus: {
        ...state.createRfpStatus,
        loading: false,
        complete: true,
        error: errorObj,
      },
    }),

    [createRfpItemStart]: (state) => ({
      ...state,
      createRfpItemStatus: {
        ...state.createRfpItemStatus,
        loading: true,
        complete: false,
        error: undefined,
      },
    }),

    [createRfpItemSuccess]: (state, { payload }) => ({
      ...state,
      createRfpItemStatus: {
        data: payload,
        loading: false,
        complete: true,
        error: undefined,
      },
    }),

    [createRfpItemFail]: (state, errorObj) => ({
      ...state,
      createRfpItemStatus: {
        ...state.createRfpItemStatus,
        loading: false,
        complete: true,
        error: errorObj,
      },
    }),

    [createBulkRfpItemsStart]: (state) => ({
      ...state,
      createBulkRfpItemsStatus: {
        ...state.createBulkRfpItemsStatus,
        loading: true,
        complete: false,
        error: undefined,
      },
    }),

    [createBulkRfpItemsSuccess]: (state, { payload }) => ({
      ...state,
      createBulkRfpItemsStatus: {
        data: payload,
        loading: false,
        complete: true,
        error: undefined,
      },
    }),

    [createBulkRfpItemsFail]: (state, errorObj) => ({
      ...state,
      createBulkRfpItemsStatus: {
        ...state.createBulkRfpItemsStatus,
        loading: false,
        complete: true,
        error: errorObj,
      },
    }),

    [cloneRfpStart]: (state) => ({
      ...state,
      cloneRfpStatus: {
        ...state.cloneRfpStatus,
        loading: true,
        complete: false,
        error: undefined,
      },
    }),

    [cloneRfpSuccess]: (state, { payload }) => ({
      ...state,
      cloneRfpStatus: {
        data: payload,
        loading: false,
        complete: true,
        error: undefined,
      },
    }),

    [cloneRfpFail]: (state, errorObj) => ({
      ...state,
      cloneRfpStatus: {
        ...state.cloneRfpStatus,
        loading: false,
        complete: true,
        error: errorObj,
      },
    }),

    [searchCatalogItemsStart]: (state) => ({
      ...state,
      searchCatalogItemsStatus: {
        ...state.searchCatalogItemsStatus,
        loading: true,
        complete: false,
        error: undefined,
      },
    }),

    [searchCatalogItemsSuccess]: (state, { payload }) => ({
      ...state,
      searchCatalogItemsStatus: {
        data: payload,
        loading: false,
        complete: true,
        error: undefined,
      },
    }),

    [searchCatalogItemsFail]: (state, errorObj) => ({
      ...state,
      searchCatalogItemsStatus: {
        ...state.searchCatalogItemsStatus,
        loading: false,
        complete: true,
        error: errorObj,
      },
    }),

    [saveRfpStart]: (state) => ({
      ...state,
      saveRfpStatus: {
        ...state.saveRfpStatus,
        loading: true,
        complete: false,
        error: undefined,
      },
    }),

    [saveRfpSuccess]: (state, { payload }) => ({
      ...state,
      saveRfpStatus: {
        data: payload,
        loading: false,
        complete: true,
        error: undefined,
      },
    }),

    [saveRfpFail]: (state, errorObj) => ({
      ...state,
      saveRfpStatus: {
        ...state.saveRfpStatus,
        loading: false,
        complete: true,
        error: errorObj,
      },
    }),

    [deleteRfpStart]: (state) => ({
      ...state,
      deleteRfpStatus: {
        data: null,
        loading: true,
        complete: false,
        error: undefined,
      },
    }),

    [deleteRfpSuccess]: (state) => ({
      ...state,
      deleteRfpStatus: {
        data: null,
        loading: false,
        complete: false,
        error: undefined,
      },
    }),

    [deleteRfpFail]: (state, errorObj) => ({
      ...state,
      deleteRfpStatus: {
        data: null,
        loading: false,
        complete: false,
        error: errorObj,
      },
    }),

    [deleteRfpItemStart]: (state) => ({
      ...state,
      deleteRfpItemStatus: {
        data: null,
        loading: true,
        complete: false,
        error: undefined,
      },
    }),

    [deleteRfpItemSuccess]: (state) => ({
      ...state,
      deleteRfpItemStatus: {
        data: null,
        loading: false,
        complete: false,
        error: undefined,
      },
    }),

    [deleteRfpItemFail]: (state, errorObj) => ({
      ...state,
      deleteRfpItemStatus: {
        data: null,
        loading: false,
        complete: false,
        error: errorObj,
      },
    }),

    [getRfpItemStart]: (state) => ({
      ...state,
      rfpItemDetails: {
        ...state.rfpItemDetails,
        loading: true,
        complete: false,
        error: undefined,
      },
    }),

    [getRfpItemSuccess]: (state, { payload }) => ({
      ...state,
      rfpItemDetails: {
        data: payload,
        loading: false,
        complete: true,
        error: undefined,
      },
    }),

    [getRfpItemFail]: (state, errorObj) => ({
      ...state,
      rfpItemDetails: {
        ...state.rfpItemDetails,
        loading: false,
        complete: true,
        error: errorObj,
      },
    }),

    [updateRfpItemStart]: (state) => ({
      ...state,
      updateRfpItemStatus: {
        ...state.updateRfpItemStatus,
        loading: true,
        complete: false,
        error: undefined,
      },
    }),

    [updateRfpItemSuccess]: (state, { payload }) => ({
      ...state,
      updateRfpItemStatus: {
        data: payload,
        loading: false,
        complete: true,
        error: undefined,
      },
    }),

    [updateRfpItemFail]: (state, errorObj) => ({
      ...state,
      updateRfpItemStatus: {
        ...state.updateRfpItemStatus,
        loading: false,
        complete: true,
        error: errorObj,
      },
    }),

    [changeRfpStateStart]: (state) => ({
      ...state,
      changeRfpStateStatus: {
        ...state.changeRfpStateStatus,
        loading: true,
        complete: false,
        error: undefined,
      },
    }),

    [changeRfpStateSuccess]: (state, { payload }) => ({
      ...state,
      changeRfpStateStatus: {
        data: payload,
        loading: false,
        complete: true,
        error: undefined,
      },
    }),

    [changeRfpStateFail]: (state, errorObj) => ({
      ...state,
      changeRfpStateStatus: {
        ...state.changeRfpStateStatus,
        loading: false,
        complete: true,
        error: errorObj,
      },
    }),
  },
  initialState,
);

export default {
  actionCreators: { ...actionCreators, ...thunks },
  reducer,
};