import React, { useEffect, useState } from 'react';
import { withRouter  } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import Navbar from './shared/Navbar';
import Sidebar from './shared/Sidebar';
// import SettingsPanel from './shared/SettingsPanel';
import './App.scss';

const App = (props) => {
  const [isFullPageLayout, setFullPageLayout] = useState(0);
  const navbarComponent = !isFullPageLayout ? <Navbar/> : '';
  const sidebarComponent = !isFullPageLayout ? <Sidebar/> : '';
  // const SettingsPanelComponent = !isFullPageLayout ? <SettingsPanel/> : '';
  // const footerComponent = !isFullPageLayout ? <Footer/> : '';

  useEffect(() => {
    const onRouteChanged = () => {
      console.log('ROUTE CHANGED');
      window.scrollTo(0, 0);
  
      const fullPageLayoutRoutes = ['/login', '/user-pages/register-1', '/user-pages/register-2', '/user-pages/lockscreen', '/error-pages/error-404', '/error-pages/error-500', '/general-pages/landing-page'];
      if (fullPageLayoutRoutes.includes(props.location.pathname)) {
        setFullPageLayout(true);
        document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
      } else {
        setFullPageLayout(false);
        document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
      }
    }
    onRouteChanged();
  }, [props.location]);

  return (
    <div className="container-scroller">
      { navbarComponent }
      <div className="container-fluid page-body-wrapper">
        { sidebarComponent }
        <div className="main-panel">
          <div className="content-wrapper">
            <AppRoutes/>
            {/* { SettingsPanelComponent } */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(App);
