import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
} from 'redux';
import thunk from 'redux-thunk';
import { persistReducer, persistStore } from 'redux-persist';
import account from '../reducers/account';
import orders from '../reducers/orders';
// import storage from 'redux-persist/lib/storage';
import storage from 'redux-persist/lib/storage/session'

const reducers = combineReducers({
  account: account.reducer,
  orders: orders.reducer,
});

const persistConfig = {
  storage,
  key: 'root',
  whitelist: ['account','orders'],
};

const middleware = [thunk];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistedReducer = persistReducer(persistConfig, reducers);

const store = createStore(
  persistedReducer,
  composeEnhancers(
    applyMiddleware(...middleware),
  ),
);

const persistor = persistStore(store);

export { store, persistor };
