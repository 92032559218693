import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Reducers from '../../reducers'
import "./Navbar.scss";

const Navbar = (props) => {
  const { 
    lang,
    setLang,
  } = props;
  const { t, i18n } = useTranslation();

  const toggleOffcanvas = () => {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }

  const signOut = () => {
    sessionStorage.clear();
    window.location.href = "/login";
  }

  const isPathActive = (path) => {
    return props.location.pathname.startsWith(path);
  }

  const getHeaderName = () => {
    if (isPathActive('/performance-summary')) return t('performance.performanceSummary');
    if (isPathActive('/price-optimization-potential')) return t('performance.priceOptimizationPotential');
    if (isPathActive('/trend-summary')) return t('performance.trendSummary');
    if (isPathActive('/rfp-vendor')) return 'Vendor RFP\'s';
    if (isPathActive('/rfp')) return  t('rfp.purchaserRfps');
    if (isPathActive('/purchaser-offers')) return t('general.offers');
    if (isPathActive('/order-history ')) return t('sidebar.orderHistory');
    if (isPathActive('/vendor-offers')) return t('general.offers');
    return 'Missing Header';
  }

  // const toggleRightSidebar = () => {
  //   document.querySelector('.right-sidebar').classList.toggle('open');
  // }
  return (
    <nav className="navbar col-lg-12 col-12 p-lg-0 fixed-top d-flex flex-row">
      <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between">
      <a className="navbar-brand brand-logo-mini align-self-center d-lg-none" href="!#" onClick={evt =>evt.preventDefault()}><img src={require("../../assets/images/logo/logo-main.png")} alt="logo" /></a>
        <button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={ () => document.body.classList.toggle('sidebar-icon-only') }>
          <i className="mdi mdi-menu"></i>
        </button>
        <div className="nav-header-title">
          {getHeaderName()}
        </div>
        <ul className="navbar-nav navbar-nav-right">
          <div className="lang-selector">
            <Dropdown>
              <Dropdown.Toggle className="lang-selector-button">
                <>
                  <img src={require(`../../assets/images/icons/${lang}-flag.png`)} alt={lang} />
                  <span className="lang-code">{lang.toUpperCase()}</span>
                </>
              </Dropdown.Toggle>
              <Dropdown.Menu className="lang-selector-items">
                <Dropdown.Item onClick={() => { setLang('en'); i18n.changeLanguage('en')}}><img src={require("../../assets/images/icons/en-flag.png")} alt="english" /> English</Dropdown.Item>
                <Dropdown.Item onClick={() => { setLang('fr'); i18n.changeLanguage('fr')}}><img src={require("../../assets/images/icons/fr-flag.png")} alt="french" /> French</Dropdown.Item>
                <Dropdown.Item onClick={() => { setLang('pt'); i18n.changeLanguage('pt')}}><img src={require("../../assets/images/icons/pt-flag.png")} alt="portuguese" /> Portuguese</Dropdown.Item>
                <Dropdown.Item onClick={() => { setLang('es'); i18n.changeLanguage('es')}}><img src={require("../../assets/images/icons/es-flag.png")} alt="spanish" /> Spanish</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <li className="nav-item  nav-profile border-0">
            <Dropdown>
              <Dropdown.Toggle className="nav-link count-indicator p-0 toggle-arrow-hide bg-transparent">
                <i className="mdi mdi-bell-outline"></i>
                {/* <span className="count bg-success">4</span> */}
              </Dropdown.Toggle>
              <Dropdown.Menu className="navbar-dropdown preview-list">
                <Dropdown.Item className="dropdown-item py-3 d-flex align-items-center" href="!#" onClick={evt =>evt.preventDefault()}>
                  <p className="mb-0 font-weight-medium float-left">You have 4 new notifications </p>
                  <span className="badge badge-pill badge-primary float-right">View all</span>
                </Dropdown.Item>
                <div className="dropdown-divider"></div>
                <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center" href="!#" onClick={evt =>evt.preventDefault()}>
                  <div className="preview-thumbnail">
                    <i className="mdi mdi-alert m-auto text-primary"></i>
                  </div>
                  <div className="preview-item-content py-2">
                    <h6 className="preview-subject font-weight-normal text-dark mb-1">Application Error</h6>
                    <p className="font-weight-light small-text mb-0"> Just now </p>
                  </div>
                </Dropdown.Item>
                <div className="dropdown-divider"></div>
                <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center" href="!#" onClick={evt =>evt.preventDefault()}>
                  <div className="preview-thumbnail">
                    <i className="mdi mdi-settings m-auto text-primary"></i>
                  </div>
                  <div className="preview-item-content py-2">
                    <h6 className="preview-subject font-weight-normal text-dark mb-1">Settings</h6>
                    <p className="font-weight-light small-text mb-0"> Private message </p>
                  </div>
                </Dropdown.Item>
                <div className="dropdown-divider"></div>
                <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center" href="!#" onClick={evt =>evt.preventDefault()}>
                  <div className="preview-thumbnail">
                    <i className="mdi mdi-airballoon m-auto text-primary"></i>
                  </div>
                  <div className="preview-item-content py-2">
                    <h6 className="preview-subject font-weight-normal text-dark mb-1">New user registration</h6>
                    <p className="font-weight-light small-text mb-0"> 2 days ago </p>
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
          <li className="nav-item  nav-profile border-0">
            <Dropdown>
              <Dropdown.Toggle className="nav-link count-indicator p-0 toggle-arrow-hide bg-transparent">
                <i className="mdi mdi-email-outline"></i>
                {/* <span className="count">7</span> */}
              </Dropdown.Toggle>
              <Dropdown.Menu className="navbar-dropdown preview-list">
                <Dropdown.Item className="dropdown-item  d-flex align-items-center" href="!#" onClick={evt =>evt.preventDefault()}>
                  <p className="mb-0 font-weight-medium float-left">You have 7 unread mails </p>
                  <span className="badge badge-pill badge-primary">View all</span>
                </Dropdown.Item>
                <div className="dropdown-divider"></div>
                <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center" href="!#" onClick={evt =>evt.preventDefault()}>
                  <div className="preview-thumbnail">
                    <img src={require("../../assets/images/faces/face10.jpg")} alt="profile" className="img-sm profile-pic" /> </div>
                  <div className="preview-item-content flex-grow py-2">
                    <p className="preview-subject ellipsis font-weight-medium text-dark">Marian Garner </p>
                    <p className="font-weight-light small-text"> The meeting is cancelled </p>
                  </div>
                </Dropdown.Item>
                <div className="dropdown-divider"></div>
                <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center" href="!#" onClick={evt =>evt.preventDefault()}>
                  <div className="preview-thumbnail">
                    <img src={require("../../assets/images/faces/face12.jpg")} alt="profile" className="img-sm profile-pic" /> </div>
                  <div className="preview-item-content flex-grow py-2">
                    <p className="preview-subject ellipsis font-weight-medium text-dark">David Grey </p>
                    <p className="font-weight-light small-text"> The meeting is cancelled</p>
                  </div>
                </Dropdown.Item>
                <div className="dropdown-divider"></div>
                <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center" href="!#" onClick={evt =>evt.preventDefault()}>
                  <div className="preview-thumbnail">
                    <img src={require("../../assets/images/faces/face1.jpg")} alt="profile" className="img-sm profile-pic" /> </div>
                  <div className="preview-item-content flex-grow py-2">
                    <p className="preview-subject ellipsis font-weight-medium text-dark">Travis Jenkins </p>
                    <p className="font-weight-light small-text"> The meeting is cancelled </p>
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
          
          
          <li className="nav-item  nav-profile border-0">
            <Dropdown>
              <Dropdown.Toggle className="nav-link count-indicator bg-transparent">
                <i className="mdi mdi-account profile-icon"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="preview-list navbar-dropdown pb-3">
                <Dropdown.Item className="dropdown-item p-0 preview-item d-flex align-items-center border-bottom" href="!#" onClick={evt =>evt.preventDefault()}>
                  <div className="d-flex">
                    <div className="py-3 px-4 d-flex align-items-center justify-content-center">
                      <i className="mdi mdi-bookmark-plus-outline mr-0"></i>
                    </div>
                    <div className="py-3 px-4 d-flex align-items-center justify-content-center border-left border-right">
                      <i className="mdi mdi-account-outline mr-0"></i>
                    </div>
                    <div className="py-3 px-4 d-flex align-items-center justify-content-center">
                      <i className="mdi mdi-alarm-check mr-0"></i>
                    </div>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center border-0 mt-2" onClick={evt =>evt.preventDefault()}>
                  Manage Accounts
                </Dropdown.Item>
                <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center border-0" onClick={evt =>evt.preventDefault()}>
                  Change Password
                </Dropdown.Item>
                <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center border-0" onClick={evt =>evt.preventDefault()}>
                  Check Inbox
                </Dropdown.Item>
                <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center border-0" onClick={evt => signOut()}>
                  Sign Out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
        <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={toggleOffcanvas}>
          <span className="mdi mdi-menu"></span>
        </button>
      </div>
    </nav>
  );
}


Navbar.propTypes = {
  lang: PropTypes.string.isRequired,
  setLang: PropTypes.func.isRequired,
};

const mapState = ({
  account: { lang },
}) => ({
  lang,
});

const {
  account: {
    actionCreators: {
      setLang,
    },
  },
} = Reducers;

const mapDispatch = dispatch => bindActionCreators(
  {
    setLang,
  },
  dispatch,
);

export default connect(mapState, mapDispatch)(
  (withRouter(Navbar))
);
