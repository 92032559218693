import apiRequest from './request';

export const registerNewUser = (username, email, password, recaptcha) => apiRequest(
  '/auth/register',
  'POST',
  null,
  {
    username,
    email,
    password,
    recaptcha,
  },
  'amx',
);

export const getClientDetails = () => apiRequest(
  '/api/client',
  'GET',
  null,
  null,
  'OAuth',
);

export const getSpecificClientDetails = (clientId) => apiRequest(
  `/api/client/${clientId}`,
  'GET',
  null,
  null,
  'OAuth',
);

export const getDeliveryLocations = () => apiRequest(
  '/api/deliveryLocations',
  'GET',
  null,
  null,
  'OAuth',
);

export const getCategories = () => apiRequest(
  '/api/categories?filterEmptyCategories=true',
  'GET',
  null,
  null,
  'OAuth',
);

export const getCategoryItems = (data) => apiRequest(
  `/api/items?${data.searchtext ? 'searchtext=' + data.searchtext : ''}${data.categoryId ? 'categoryId=' + data.categoryId : ''}`,
  'GET',
  null,
  null,
  'OAuth',
);

export const getUnitTypes = () => apiRequest(
  '/api/unittypes',
  'GET',
  null,
  null,
  'OAuth',
);

export const getIncoTerms = () => apiRequest(
  '/api/incoterms',
  'GET',
  null,
  null,
  'OAuth',
);
