import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import translationsEN from "./langs/en.json";
import translationsFR from "./langs/fr.json";
import translationsPT from "./langs/pt.json";
import translationsES from "./langs/es.json";

const resources = {
  en: {
    translations: translationsEN,
  },
  fr: {
    translations: translationsFR,
  },
  pt: {
    translations: translationsPT,
  },
  es: {
    translations: translationsES,
  }
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
      resources,
      fallbackLng: "en",
      debug: false,

      // have a common namespace used around the full app
      ns: ["translations"],
      defaultNS: "translations",

      keySeparator: false, // we use content as keys

      interpolation: {
      escapeValue: false
    }
  });

  export default i18n;